html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
}

body,
.mapboxgl-map,
input,
button {
  font-family: Calibre, Arial, Helvetica, sans-serif !important;
}

a,
button,
input {
  outline: none;
}

:root {
  --size: 40px;
  --halfSize: calc(var(--size) / 2);
  --loaderBorder: 5px;
  --loaderHalfBorder: calc(var(--loaderBorder) / 2);
  --loaderDoubleBorder: calc(var(--loaderBorder) * 2);
}

/**
 * Loader
 */
#loader-container {
  width: var(--size);
  height: var(--size);
  position: relative;
}
#loader-border {
  border: var(--loaderBorder) solid black;
  width: 100%;
  height: 100%;
  position: absolute;
}
#loader-dot {
  background-color: red;
  width: var(--loaderBorder);
  height: var(--loaderBorder);
  position: absolute;
  top: 0px;
  left: 0px;
  animation: loader-moving 4s infinite;
  will-change: transform;
}

@keyframes loader-moving {
  0%,
  100% {
    transform: translate(
      calc(var(--halfSize) - var(--loaderHalfBorder)),
      calc(var(--loaderBorder))
    );
  }
  25% {
    transform: translate(
      calc(var(--size) - var(--loaderDoubleBorder)),
      calc(var(--halfSize) - var(--loaderHalfBorder))
    );
  }
  50% {
    transform: translate(
      calc(var(--halfSize) - var(--loaderHalfBorder)),
      calc(var(--size) - var(--loaderDoubleBorder))
    );
  }
  75% {
    transform: translate(
      calc(var(--loaderBorder)),
      calc(var(--halfSize) - var(--loaderHalfBorder))
    );
  }
}
